<template>
  <section>
    <aTable
      class="travel-table"
      :columns="contractTableColumns"
      :rowClassName="contractTableRowClass"
      :data-source="contract.list"
      :loading="contract.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="contractTableChange"
    >
      <div slot="first_checkin" slot-scope="record">
        <aTooltip
          v-if="
            checkinIsComing(record) &&
            record.meta.manual_anti_fraud !== 'Concluída'
          "
          placement="top"
        >
          <template #title>
            <div class="f13s">
              O check-in deste contrato <br />
              está próximo:
              <b> {{ record.first_checkin | formatMultiDates }} </b>
            </div>
          </template>

          <img
            src="@/assets/images/dashboard/contracts/fraud-red.png"
            width="15"
            alt="img"
          />
        </aTooltip>

        <img
          v-if="record.meta.manual_anti_fraud === 'Concluída'"
          src="@/assets/images/dashboard/contracts/fraud-green.png"
          width="15"
          alt="img"
        />
      </div>

      <div slot="alert" slot-scope="record">
        <aTooltip v-if="alertContractUser(record)" placement="top">
          <template slot="title">
            Atenção, seu contrato esta há mais de 1 dia em aberto
          </template>
          <aIcon class="corange c-pointer f16" type="warning" />
        </aTooltip>
      </div>

      <div slot="customer" slot-scope="record">
        <div
          class="dotted-phrase upper c-pointer"
          @click="$router.push(`/customers/edit/${record.customer.id}`)"
        >
          <span v-if="!contract.loading" class="recurring-customer f10">
            <aTooltip v-if="record.customer.is_new_customer == true">
              <template slot="title"> Novo Cliente </template>

              <span
                ><i class="fa-solid fa-handshake" style="color: #8cc152"></i
              ></span>
            </aTooltip>

            <aTooltip v-if="record.customer.is_new_customer == false">
              <template slot="title"> Cliente Recorrente </template>
              <span>
                <i
                  class="fa fa-user-plus"
                  aria-hidden="true"
                  style="color: #fc6e51"
                ></i
              ></span>
            </aTooltip>
          </span>
          <span
            v-if="record.customer.person_type == 'Pessoa Física'"
            class="ml-5"
            >{{ record.customer.id }} - {{ record.customer.first_name }}
            {{ record.customer.last_name }}</span
          >
          <span
            v-if="record.customer.person_type == 'Pessoa Jurídica'"
            class="ml-5"
            >{{ record.customer.id }} - {{ record.customer.trading_name }}
          </span>
        </div>
      </div>

      <div slot="contracted_services" slot-scope="record">
        <aTooltip
          class="travel-tooltip"
          placement="top"
          style="display: inline-block"
        >
          <template slot="title">
            <div v-html="formatProductsTxt(record.meta)" />
          </template>
          <a-tag
            v-for="(product, index) in formatContractedServices(
              record.contracted_services
            )"
            :key="index"
            :color="product.color"
          >
            <div v-html="product.name" />
          </a-tag>
        </aTooltip>
      </div>

      <div slot="flight_is_ticketed" slot-scope="record">
        <div v-if="record.flight_is_ticketed == 1">
          <aIcon type="star" theme="filled" style="color: #faad14" />
        </div>
      </div>

      <div slot="liveloTitle">
        <img
          src="@/assets/images/dashboard/contracts/livelo-color.png"
          width="14"
          alt="img"
        />
      </div>

      <div slot="marketing_types" slot-scope="record">
        <div v-if="record.marketing_types">
          <div v-if="record.marketing_types.includes('Livelo')">
            <aIcon type="check" class="cgreen" />
          </div>
        </div>
      </div>

      <div slot="value" slot-scope="record">
        <div v-html="formatPrice(record.value)"></div>
      </div>

      <div slot="profit_percentage" slot-scope="record">
        <div v-html="contractProfit(record)"></div>
      </div>

      <div slot="payment_methods" slot-scope="record">
        <span class="payment-types">
          <span v-for="(payment, index) in formatPayments(record)" :key="index">
            <aTooltip
              v-if="payment.type == 'credit-card'"
              placement="top"
              :title="payment.name"
            >
              <img
                src="@/assets/images/dashboard/credit-card.png"
                height="26"
                alt="img"
              />
            </aTooltip>

            <aTooltip
              v-if="payment.type == 'payment-link'"
              placement="top"
              :title="payment.name"
            >
              <img
                src="@/assets/images/dashboard/link.png"
                height="20"
                alt="img"
              />
            </aTooltip>

            <aTooltip
              v-if="payment.type == 'billing-ticket'"
              placement="top"
              :title="payment.name"
            >
              <img
                src="@/assets/images/dashboard/barcode.png"
                height="26"
                alt="img"
              />
            </aTooltip>
            <aTooltip
              v-if="payment.type == 'bank-transfer'"
              placement="top"
              :title="payment.name"
            >
              <img
                src="@/assets/images/dashboard/bank-transfer.png"
                height="22"
                alt="img"
              />
            </aTooltip>
            <aTooltip
              v-if="payment.type == 'payment-card'"
              placement="top"
              :title="payment.name"
            >
              <img
                src="@/assets/images/dashboard/pay-card.png"
                height="22"
                alt="img"
              />
            </aTooltip>
            <aTooltip
              v-if="payment.type == 'custom-payment'"
              placement="top"
              :title="payment.name"
            >
              <img
                src="@/assets/images/dashboard/custom-payment.png"
                height="23"
                alt="img"
              />
            </aTooltip>
          </span>
        </span>
      </div>

      <div slot="company_branch" slot-scope="record">
        <div class="truncate">
          {{ record.company_branch.name }}
        </div>
      </div>

      <div slot="release_date" slot-scope="record">
        <aTooltip class="travel-tooltip" placement="top">
          <template slot="title">
            Criado em: {{ formatDateTime(record.created) }}
          </template>
          {{
            record.release_date != "0000-00-00"
              ? formatDate(record.release_date)
              : formatDateTime(record.created)
          }}
        </aTooltip>
      </div>

      <div slot="user" slot-scope="record">
        <aTooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </aTooltip>
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <div v-if="!contract.loading">
          <aTooltip
            v-if="$root.isAdmin() || $root.isMilesManager()"
            placement="top"
            title="Editar"
          >
            <a class="edit ml-15" @click="edit(record.id)">
              <aIcon type="edit-svg" />
            </a>
          </aTooltip>

          <span v-if="record.allow_user_edit != '0'">
            <aTooltip
              v-if="
                !$root.isAdmin() &&
                record.status != 'concluded' &&
                !$root.isMilesManager() &&
                record.status != 'concluded'
              "
              placement="top"
              title="Editar"
            >
              <a class="edit ml-15" @click="edit(record.id)">
                <aIcon type="edit-svg" />
              </a>
            </aTooltip>
          </span>

          <aTooltip
            v-if="
              !$root.isAdmin() &&
              record.status == 'concluded' &&
              !$root.isMilesManager() &&
              record.status == 'concluded'
            "
            placement="top"
            title="Ver"
          >
            <a class="view ml-15" @click="view(record.id)">
              <aIcon
                slot="prefix"
                :type="
                  record.status == 'concluded' ? 'green-eye-svg' : 'eye-svg'
                "
              />
            </a>
          </aTooltip>

          <aTooltip
            v-if="$root.isAdmin() || $root.isMilesManager()"
            placement="top"
            title="Ver"
          >
            <a class="view ml-15" @click="view(record.id)">
              <aIcon
                slot="prefix"
                :type="
                  record.status == 'concluded' ? 'green-eye-svg' : 'eye-svg'
                "
              />
            </a>
          </aTooltip>

          <aTooltip
            v-if="requestMiles(record).show"
            placement="top"
            title="Ver pedidos de milhas"
          >
            <a class="view ml-15 cgreen" @click="viewMilesRequests(record)">
              <aIcon type="credit-card" style="font-size: 17px" />
            </a>
          </aTooltip>

          <aTooltip placement="top" title="Resumo">
            <a
              class="edit ml-15 relative"
              v-on:click.ctrl="viewContractDetails(record.id)"
              v-on:click.exact="viewContractDetails(record.id)"
              style="top: -4px"
            >
              <img
                src="@/assets/images/dashboard/contracts/details-ico.png"
                width="19"
                alt="img"
              />
            </a>
          </aTooltip>
        </div>
      </div>
    </aTable>

    <div class="a-center mt-30">
      <aPagination
        show-size-changer
        :default-current="contract.pagination.page"
        :page-size-options="pageSizeOptions"
        :page-size.sync="contract.pagination.perPage"
        :total="contract.pagination.total"
        @change="contractChangePage"
        @showSizeChange="contractChangePageSize"
      >
      </aPagination>
    </div>

    <aModal
      class="contract-details"
      width="884px"
      v-model="openContractDetailsModal"
      :title="false"
      :footer="false"
      @cancel="openContractDetailsModal = false"
    >
      <ContractModal
        v-if="openContractDetailsModal"
        :contractId="contractId"
        @updateContractsList="updateContractsList"
      />
    </aModal>

    <aModal
      class="miles-request-modal"
      v-model="openMilesRequestModal"
      :footer="false"
      :width="$root.windowWidth > 700 ? '940px' : '100%'"
      @ok="openMilesRequestModal = false"
    >
      <template slot="title">
        <aIcon
          type="credit-card"
          style="
            top: 3px;
            margin-right: 6px;
            font-size: 20px;
            font-size: 20px;
            color: #00aa21;
            position: relative;
          "
        />
        PEDIDOS DE MILHAS
      </template>

      <RequestMiles
        v-if="openMilesRequestModal"
        :form="form"
        :showActions="false"
        :allowMilesRequest="requestMiles().show"
        :tempContract="tempContract"
        :milesCardOrder="milesCardOrder"
        :milesRequestList="airlinesList"
        :show="openMilesRequestModal"
        @updateTempContract="openMilesRequestModal = false"
        @closeRequestModal="openMilesRequestModal = false"
      />
    </aModal>
  </section>
</template>

<script>
import {
  format,
  isToday,
  isYesterday,
  getTime,
  parse,
  parseISO,
  isAfter,
  isBefore,
  addDays,
  isValid,
} from "date-fns";
import RequestMiles from "@/components/contracts/forms/RequestMiles.vue";
import ContractModal from "@/components/contracts/modals/ContractModal.vue";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ContractListTable",
  props: {
    contract: Object,
  },
  components: { RequestMiles, ContractModal },
  mixins: [formatThings],
  data() {
    return {
      tempContract: {},
      contractId: null,
      openMilesRequestModal: false,
      openContractDetailsModal: false,
      pageSizeOptions: ["10", "20", "50", "100", "200", "500", "1000"],
      contractTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "",
          width: 30,
          scopedSlots: { customRender: "first_checkin" },
          sorter: true,
          key: "first_checkin",
        },
        {
          title: "",
          width: 30,
          scopedSlots: { customRender: "alert" },
        },
        {
          title: "Contratante",
          scopedSlots: { customRender: "customer" },
          width: 180,
        },
        {
          title: "Produtos",
          scopedSlots: { customRender: "contracted_services" },
          width: 160,
        },
        {
          title: "★",
          scopedSlots: { customRender: "flight_is_ticketed" },
          width: 30,
          sorter: true,
          key: "flight_is_ticketed",
        },
        {
          slots: { title: "liveloTitle" },
          scopedSlots: { customRender: "marketing_types" },
          width: 30,
        },
        {
          title: "Valor",
          scopedSlots: { customRender: "value" },
          key: "value",
          width: 110,
          sorter: true,
        },
        {
          title: "",
          scopedSlots: { customRender: "profit_percentage" },
          width: 80,
          sorter: true,
          key: "profit_percentage",
        },
        {
          title: "Pagamentos",
          scopedSlots: { customRender: "payment_methods" },
          width: 130,
        },

        {
          title: "Empresa/Filial",
          scopedSlots: { customRender: "company_branch" },
          sorter: true,
          key: "company_branch_id",
          width: 130,
        },
        {
          title: "Criação/Lançamento",
          scopedSlots: { customRender: "release_date" },
          width: 170,
          sorter: true,
          key: "created",
        },
        {
          title: "",
          scopedSlots: { customRender: "user" },
          width: 60,
          key: "user",
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 180,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("open-modal") && urlParams.get("open-id"))
      this.viewContractDetails(urlParams.get("open-id"));
  },
  methods: {
    edit(id) {
      this.$router.push(`/contracts/edit/${id}`);
    },
    view(id) {
      this.$router.push(`/contracts/view/${id}`);
    },
    viewContractDetails(id) {
      this.contractId = id;
      this.openContractDetailsModal = true;
    },
    viewMilesRequests(contract) {
      this.openMilesRequestModal = true;
      this.tempContract = contract;
    },
    updateContractsList() {
      this.openContractDetailsModal = false;
      this.$emit("getContracts", this.contract.pagination.page);
    },
    contractChangePage(current) {
      this.contract.pagination.page = current;
      this.$emit("getContracts");
    },
    contractChangePageSize(current, pageSize) {
      this.contract.pagination.page = current;
      this.contract.pagination.perPage = pageSize;
      this.$emit("getContracts");
    },
    checkinIsComing(contract) {
      let flag = false;
      if (
        contract.status === "concluded" &&
        this.isDateWithinNextDays(
          contract.first_checkin,
          contract.company_branch.days_to_checkin_alert
        )
      )
        flag = true;
      return flag;
    },
    isDateWithinNextDays(dateStr, days) {
      // Check if dateStr is provided
      if (!dateStr) return false;

      // Parse the date string
      const targetDate = parseISO(dateStr);

      // Check if the parsed date is valid
      if (!isValid(targetDate)) return false;

      // Get today's date
      const today = new Date();

      // Get the date 90 days from today
      const dateIn90Days = addDays(today, days);

      // Check if the target date is between today and the date 90 days from today
      return isAfter(targetDate, today) && isBefore(targetDate, dateIn90Days);
    },
    requestMiles(contract) {
      let flag = false,
        airlineFlag = false,
        totalMiles = 0,
        tempContract = {};

      if (contract != undefined) {
        tempContract = contract.meta;

        if (tempContract.flight_sections_rows != undefined) {
          JSON.parse(tempContract.flight_sections_rows).forEach((flight) => {
            if (tempContract[`flight_${flight.id}_type`] == "Milhas") {
              // FLIGHT AIRLINES ROWS
              flight.airlines.forEach((airline) => {
                let airlineTravellers = [];
                if (
                  (tempContract[
                    `flight_${flight.id}_airline_${airline.id}_travellers`
                  ] &&
                    tempContract[
                      `flight_${flight.id}_airline_${airline.id}_miles_program`
                    ]) ||
                  (tempContract[
                    `flight_${flight.id}_airline_${airline.id}_miles_program`
                  ] &&
                    tempContract[
                      `flight_${flight.id}_airline_${airline.id}_travellers`
                    ])
                ) {
                  airlineTravellers = JSON.parse(
                    tempContract[
                      `flight_${flight.id}_airline_${airline.id}_travellers`
                    ]
                  );
                  if (airlineTravellers.length > 0) {
                    airlineFlag = true;
                  }
                } else {
                  airlineFlag = false;
                }
              });
              // FLIGHT TRAVELLERS ROWS
              flight.travellers.forEach((traveller) => {
                if (
                  (tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_miles`
                  ] &&
                    tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_value`
                    ] &&
                    tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes`
                    ] &&
                    tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                    ]) ||
                  (tempContract[
                    `flight_${flight.id}_traveller_${traveller.id}_miles`
                  ] &&
                    tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_value`
                    ] &&
                    tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes`
                    ] &&
                    tempContract[
                      `flight_${flight.id}_traveller_${traveller.id}_taxes_currency`
                    ])
                ) {
                  flag = true;
                } else {
                  flag = false;
                }
              });
            }
          });
        }
      }

      return { show: flag && airlineFlag, miles: totalMiles };
    },
    formatPercentage(percentage) {
      let thePercentage = 0;
      if (percentage) thePercentage = percentage;

      return `${Number(parseFloat(thePercentage).toFixed(2)).toLocaleString(
        "pt-BR"
      )}`;
    },
    formatPrice(price) {
      return price
        ? `<span class="ant-tag ant-tag-price">${Number(
            parseFloat(price.replace(",", ".")).toFixed(2)
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}</span>`
        : `---`;
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    formatDateTime(date) {
      const dateObj = new Date(date);
      if (isToday(dateObj)) {
        return `Hoje ${format(dateObj, "HH:mm")}`;
      } else if (isYesterday(dateObj)) {
        return `Ontem ${format(dateObj, "HH:mm")}`;
      } else {
        return format(dateObj, "dd/MM/yyyy HH:mm");
      }
    },
    contractTableRowClass(record, index) {
      return `${record.id} ${record.status} ${
        index + 2 == this.contract.meta.total_edit_status + 1 &&
        record.status == "edit"
          ? "last-edit"
          : ""
      }`;
    },

    formatPayments(contract) {
      let allPayments = [];

      const paymentMethods =
        contract.payment_methods != undefined
          ? JSON.parse(contract.payment_methods)
          : [];

      if (paymentMethods.length > 0) {
        paymentMethods.forEach((payment) => {
          if (payment == "credit-card")
            allPayments.push({
              name: "Cartão de Crédito",
              color: "orange",
              type: payment,
            });

          if (payment == "billing-ticket")
            allPayments.push({
              name: "Boleto",
              color: "purple",
              type: payment,
            });

          if (payment == "payment-card")
            allPayments.push({
              name: "Carta de Crédito",
              color: "blue",
              type: payment,
            });

          if (payment == "custom-payment")
            allPayments.push({
              name: `Personalizado`,
              color: "blue",
              type: payment,
            });

          if (payment == "bank-transfer")
            allPayments.push({
              name: "Transferência Bancária",
              color: "blue",
              type: payment,
            });

          if (payment == "payment-link")
            allPayments.push({
              name: "Link de Pagamento",
              color: "orange",
              type: payment,
            });
        });
      }

      return allPayments;
    },
    contractTableChange(pagination, filters, sorter) {
      this.contract.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.contract.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "status";

      this.$emit("getContracts");
    },
    alertContractUser(contract) {
      let flag = false,
        now = new Date().getTime();

      if (
        contract.status == "edit" &&
        now - 86400000 > getTime(new Date(contract.created))
      )
        flag = true;

      return flag;
    },
    contractProfit(contract) {
      let html = `<div class=" f11">0%</div>`,
        percentage = 0;

      percentage = contract.profit_percentage
        ? parseFloat(contract.profit_percentage)
        : 0;

      if (percentage > 11)
        html = `<div class="cgreen f11">${this.formatPercentage(
          percentage
        )}%</div>`;

      if (percentage <= 11 && percentage >= 0)
        html = `<div class=" f11">${this.formatPercentage(percentage)}%</div>`;

      if (percentage < 0)
        html = `<div class="red f11">${this.formatPercentage(
          percentage
        )}%</div>`;

      return html;
    },
    formatContractedServices(contracted_services) {
      const theContractedServices = [];

      if (contracted_services != undefined && contracted_services != "") {
        JSON.parse(contracted_services).forEach((product) => {
          if (product == "hotel")
            theContractedServices.push({
              name: "Hotel",
              color: "green",
            });

          if (product == "flight")
            theContractedServices.push({
              name: "Aéreo",
              color: "blue",
            });

          if (product == "service")
            theContractedServices.push({
              name: "Serviço",
              color: "orange",
            });
        });
      } else {
        theContractedServices.push({
          name: "NÃO INFORMADO",
          color: "purple",
        });
      }

      return theContractedServices;
    },
    formatProductsTxt(meta) {
      let txt = "";

      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          // HOTEL
          if (p == "hotel") {
            if (meta.hotel_rows) {
              JSON.parse(meta.hotel_rows).forEach((row) => {
                if (meta[`hotel_${row.id}_name`] != undefined) {
                  txt += meta[`hotel_${row.id}_name`]
                    ? meta[`hotel_${row.id}_name`] + "<br>"
                    : `HOTEL ${row.id} <br>`;
                }
              });
            }
          }

          //AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                if (index == 0)
                  txt += `AÉREO - ${meta[`flight_${row.id}_class`]} - SAÍDA: ${
                    meta[`flight_${row.id}_origin`]
                  } <br>`;
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    txt += `AÉREO ${flight.id}  ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈ ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br> `;
                  }

                  if (
                    index == flight.sections.length - 1 &&
                    flight.sections.length > 1
                  ) {
                    txt += `AÉREO ${flight.id} ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈  ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br>`;
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service") {
            if (meta.service_rows != undefined) {
              JSON.parse(meta.service_rows).forEach((row) => {
                if (meta[`service_${row.id}_name`] != undefined)
                  txt += meta[`service_${row.id}_name`];
              });
            }
          }
        });
      }

      return txt.toUpperCase();
    },
  },
};
</script>

<style>
.ant-table-tbody tr.edit:first-child {
  position: relative;
}

.ant-table-tbody tr.edit:first-child > td {
  padding-top: 37px !important;
}

.ant-table-tbody tr.edit:first-child > td.id:nth-child(1):before {
  content: "Em aberto";
  position: absolute;
  top: 3px;
  color: #aaa;
  padding: 10px 10px 10px 10px;
  left: 0;
  right: 0;
}

.ant-table-tbody tr.last-edit {
  position: relative;
}

.ant-table-tbody tr.last-edit > td {
  padding-bottom: 47px !important;
}

.ant-table-tbody tr.last-edit > td.id:nth-child(1):after {
  content: "Fechados";
  position: absolute;
  bottom: 0px;
  color: #aaa;
  padding: 10px;
  left: 0;
  right: 0;
}
</style>

<style lang="sass" scoped>
#avatar
  opacity: 0.7
  transition: .3s
#avatar:hover
  opacity: 1
</style>
